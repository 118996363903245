.gallery-container {
  width: 95%;
  padding: 20px;
  max-width: 1400px;
  margin: 10px auto;
  background: url(https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736767910164.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.gallery-container-inner {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.gallery-info {
  width: 35%;
}

.gallery-image-video {
  width: 75%;
}

.gallery-info h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
}

.gallery-info p {
  font-size: 1rem;
  /* color: #333; */
  color: #ffffff;
  font-weight: 600;
  margin: 10px 0;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.image {
  position: relative;
  width: 100%;
  height: 23rem;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.image iframe {
  border: none;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.pagination-buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-buttons button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination-buttons button:hover:not(:disabled) {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .gallery-container {
    display: block;
  }
  .gallery-container-inner {
    display: block;
  }

  .gallery-info {
    width: 100%;
  }

  .gallery-image-video {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .gallery-images {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}
