.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  align-self: center;
  justify-content: center;
  font-size: 35px;
  z-index: 100;
}

.whatsapp_float:hover {
  color: #fff;
}

.whatsapp {
  color: #25d366;
}

.whatsapp-icon {
  margin-top: 13px;
}

.floating_button {
  position: fixed;
  color: #fff;
  text-align: center;
  right: 20px;
  bottom: 110px;
  /* align-self: center; */
  /* justify-content: center; */
  z-index: 100;
}

.floating_button_1 {
  bottom: 100px;
  right: 40px;
}

.floating_button.enquireLogobox {
  overflow: hidden;
  place-items: center;
}

.enquireLogo.whatsapp_float {
  border: 1px solid gray;
  bottom: 120px;
  background: white;
  display: grid;
  border-radius: 50%;
  overflow: hidden;
  place-items: center;
  /* padding: 5px; */
}

.enquireLogo.whatsapp_float.img {
  width: 80%;
  height: 80%;
}

@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }

  .enquireLogo.whatsapp_float {
    bottom: 70px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  /* max-width:65%; */
  margin: 0 auto;
}

.thumbnail {
  width: 100%;
  padding-top: 37%;
  background-size: cover;
  margin: 0px !important;
  background-position: center;
  cursor: pointer;
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 0.5em;
  cursor: pointer;
}

.video-iframe iframe {
  width: 100% !important;
  /* height: 60vh !important; */
  border: none;
}

@media screen and (max-width: 767px) {
  .thumbnail {
    padding-top: 100%;
    padding-left: 100%;
  }

  .video-container {
    max-width: 100%;
    height: 100%;
    /* margin: 0% 3%; */
    /* margin-bottom: auto; */
  }
  .video-iframe iframe {
    height: 100% !important;
    /* height: 100% !important; */
  }
}
