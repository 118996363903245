.carousel-container-slide {
  width: 95%;
  padding: 20px;
  max-width: 1400px;
  background-image: url("https://byd-assets.s3.us-east-2.amazonaws.com/production/uploads/image-upload-1736767910164.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px auto;
}

.carousel-title {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: bounce 0.4s ease infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.card:nth-child(odd) {
  animation: bounce-up 4s ease infinite;
}

.card:nth-child(even) {
  animation: bounce-down 4s ease infinite;
}

@keyframes bounce-up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.card-designation {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

.card-description {
  font-size: 14px;
  color: #555;
}

.card-footer {
  margin-top: 10px;
}

.social-icon {
  width: 60px;
  height: 60px;
  margin-top: -30px;
  color: #1877f2;
  cursor: pointer;
}

.social-button {
  position: relative;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.social-button::before {
  content: "";
  position: absolute;
  left: -120%;
  top: 85%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/tab_arrow_image.png");
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.social-button.active::before {
  opacity: 1;
  transform: translateY(-50%) translateX(5px);
}

.slider-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px; /* Adjust gap as needed */
  width: calc(100% - 180px);
}

/* 2 columns for screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* 3 columns for screen widths above 1024px */
@media (min-width: 1025px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .slider-box {
    display: inline !important;
  }
  .social-button::before {
    display: none !important;
  }
  .social-buttons {
    text-align: center;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 0 0 20px 0;
  }

  .social-button.active {
    padding: 5px;
    border: 1px solid transparent;
  }
  .social-button.active {
    border-color: white;
    border-radius: 5px;
  }
  .grid-container {
    width: 100%;
  }
}